import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import showdown from "showdown";

import MediaSoundcloud from "../components/MediaSoundcloud";
import MediaYoutube from "../components/MediaYoutube";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";

import { videos, audios } from "../content/data/coro-polifonico";
import Footer from "../components/Footer";
import Image from "../components/Image";
import { graphql } from "gatsby";
import Heading from "../components/Heading";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const BioContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Bio = styled.div``;

const bio = `Según la Real Academia Española la polifonía es el “Conjunto de sonidos simultáneos en que cada uno expresa su idea musical, pero formando con los demás un todo armónico”. En virtud de esto es que nuestro coro polifónico se compone de hermanos y hermanas que alaban al Señor con sus voces entonadas al unísono, acompañados de un hermoso piano. 

Este bello y destacado coro alaba al Señor en cada reunión general realizada los días domingo, como así también en cada servicio especial que se realiza en nuestro templo, como por ejemplo la convención de nuestra iglesia y el servicio de acción de gracias. 

A cargo de la dirección de este coro se encuentra nuestra hermana Paola Rodríguez y nuestros hermanos Américo Peralta y Esteban Alegría.

Los ensayos se realizan los días domingo a las 16:00 horas en nuestro templo catedral.`;

export default class CoroPolifonicoPage extends Component {
  constructor() {
    super();

    this.state = {
      filters: {
        video: true,
        audio: false
      }
    };
  }

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Seo title="Coro Polifónico" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Coro Polifónico</Heading>
                  <Image fluid={data.profileImage.childImageSharp.fluid} />
                  <BioContainer>
                    <Heading>Sobre Nuestro Coro</Heading>
                    <Bio
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(bio)
                      }}
                    />
                  </BioContainer>
                  {/*<Heading>Multimedia</Heading>*/}
                  {/*<MediaMenu*/}
                  {/*  onChange={(filters) => this.setState({filters})}*/}
                  {/*/>*/}
                  {/*<Grid container>*/}
                  {/*  {*/}
                  {/*    this.renderVideos(this.state.filters.video)*/}
                  {/*  }*/}
                  {/*  {*/}
                  {/*    this.renderAudios(this.state.filters.audio)*/}
                  {/*  }*/}
                  {/*</Grid>*/}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }

  renderVideos(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {videos.map(media => {
          switch (media.source) {
            case "youtube":
              return this.renderYoutube(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderAudios(active) {
    const style = {};

    if (!active) {
      style.display = "none";
    }

    return (
      <Grid style={style} container spacing={40}>
        {audios.map(media => {
          switch (media.source) {
            case "soundcloud":
              return this.renderSoundcloud(media);
            default:
              return null;
          }
        })}
      </Grid>
    );
  }

  renderYoutube({ title, date, videoId, description }) {
    return (
      <Grid item xs={12}>
        <MediaYoutube
          title={title}
          date={date}
          videoId={videoId}
          description={description}
        />
      </Grid>
    );
  }

  renderSoundcloud({ title, date, url, description }) {
    return (
      <Grid item xs={12}>
        <MediaSoundcloud
          title={title}
          date={date}
          url={url}
          description={description}
        />
      </Grid>
    );
  }
}

export const query = graphql`
  query CoroPolifonicoPageQuery {
    profileImage: file(relativePath: { eq: "DSC07304.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
